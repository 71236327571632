var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Vue from "vue";
import gql from "graphql-tag";
import { mapMutations } from "vuex";
export default Vue.extend({
    data: function () {
        return {
            timeoutId: -1,
        };
    },
    apollo: {
        cart: {
            query: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        query PurchaseSuccessQuery($id: ID!) {\n          cart(id: $id) {\n            id\n          }\n        }\n      "], ["\n        query PurchaseSuccessQuery($id: ID!) {\n          cart(id: $id) {\n            id\n          }\n        }\n      "]))),
            variables: function () {
                return {
                    id: this.$route.params.cartId,
                };
            },
            result: function (result) {
                var _this = this;
                if (this.$ssrContext) {
                    return;
                }
                if (result.data && !result.data.cart) {
                    return;
                }
                this.timeoutId = window.setTimeout(function () { return _this.$apollo.queries.cart.refetch(); }, 2000);
            },
        },
    },
    mounted: function () {
        this.clearCart();
    },
    beforeDestroy: function () {
        if (!this.$ssrContext) {
            window.clearTimeout(this.timeoutId);
        }
    },
    methods: mapMutations(["clearCart"]),
});
var templateObject_1;
